import { Controller, useForm } from "react-hook-form";
import { TargetGroupResponseModel, useSendPushNotificationFunctionRequest } from "shared/request/myHealthyAdvantageApi";

import { ClientDivisionSelector } from "../../UIPalette/components/ClientDivisionSelector/ClientDivisionSelector";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { FormSubmitButton } from "../../UIPalette/components/Form/FormSubmitButton";
import { Hideable } from "shared/UI/Hideable/Hideable";
import Input from "@brighthr/component-input";
import { LocaleSelect } from "shared/components/LocaleSelect";
import { TargetGroupTypeAhead } from "../../UIPalette/components/TargetGroupTypeAhead/TargetGroupTypeAhead";
import Textarea from "@brighthr/component-textarea";
import { getServerErrorMessage } from "shared/utils/serverError";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Inputs = {
  sendTo: string;
  clientDivision: { clientId: string; divisionId: string };
  messageTitle: string;
  content: string;
  targetGroup: TargetGroupResponseModel;
  localeId: string;
};

const radioOptions = ["clientDivision", "targetGroup", "localeId", "perksAndDiscounts"];

export const PushNotifications = () => {
  const { t } = useTranslation();
  const [sendingNotification, setSendingNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [targetType, setTargetType] = useState("");

  const resetValues = (fields: any[]) => {
    fields.forEach((field) => {
      setValue(field, undefined);
    });
  };

  const resetUnselected = (selected: string) => {
    if (selected === "all") {
      return;
    }
    const unselected = radioOptions.filter((v) => v !== selected);
    resetValues(unselected);
  };

  const selectRadioButton = (target: string) => {
    setTargetType(target);
    resetUnselected(target);
  };

  const { trigger: sendPushTrigger } = useSendPushNotificationFunctionRequest();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger: validationTrigger,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      sendTo: "allUsers",
      messageTitle: "",
      content: "",
      clientDivision: undefined
    },
  });

  const onSubmit = async (inputs: Inputs) => {
    const isFormValid = await validationTrigger();
    if (isFormValid) {
      setErrorMessage("");
      setSendingNotification(true);
      try {
        await sendPushTrigger({
          body: {
            title: inputs.messageTitle,
            content: inputs.content,
            clientId: inputs.clientDivision?.clientId,
            divisionId: inputs.clientDivision?.divisionId,
            targetGroupId: inputs.targetGroup?.id,
            localeId: inputs.localeId,
            onlyPerksAndDiscounts: inputs.sendTo === "perksAndDiscounts" ? true : undefined,
          },
        });
        toastNotify(t("pushNotifications.successToast"), "success");
        reset();
        selectRadioButton("all");
      } catch (error: any) {
        setErrorMessage(getServerErrorMessage(error, t("pushNotifications.requestErrorMessage")));
      } finally {
        setSendingNotification(false);
      }
    }
  };

  return (
    <div>
      <h2 className="mb-4 text-black">{t("pushNotifications.title")}</h2>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={"sendTo"}
          control={control}
          render={({ field: { onChange } }) => (
            <>
              <label>{t("pushNotifications.sendToTitle")}</label>
              <div onChange={onChange} className="flex items-center px-3 space-x-2">
                <input
                  type="radio"
                  id="sendToAllUsers"
                  name="sendTo"
                  value="allUsers"
                  checked={getValues("sendTo") === "allUsers"}
                  onChange={() => selectRadioButton("all")}
                />
                <label htmlFor="sendToAllUsers">{t("pushNotifications.sendTo.allUsers")}</label>

                <input
                  type="radio"
                  id="sendToSpecificClient"
                  name="sendTo"
                  value="specificClient"
                  checked={getValues("sendTo") === "specificClient"}
                  onChange={() => selectRadioButton("clientDivision")}
                />
                <label htmlFor="sendToSpecificClient">{t("pushNotifications.sendTo.specificClient")}</label>

                <input
                  type="radio"
                  id="sendToSpecificTargetGroup"
                  name="sendTo"
                  value="specificTargetGroup"
                  checked={getValues("sendTo") === "specificTargetGroup"}
                  onChange={() => selectRadioButton("targetGroup")}
                />
                <label htmlFor="sendToSpecificTargetGroup">{t("pushNotifications.sendTo.specificTargetGroup")}</label>

                <input
                  type="radio"
                  id="sendToSpecificLocale"
                  name="sendTo"
                  value="specificLocale"
                  checked={getValues("sendTo") === "specificLocale"}
                  onChange={() => selectRadioButton("localeId")}
                />
                <label htmlFor="sendToSpecificLocale">{t("pushNotifications.sendTo.specificLocale")}</label>

                <input
                  type="radio"
                  id="sendToPerksAndDiscounts"
                  name="sendTo"
                  value="perksAndDiscounts"
                  checked={getValues("sendTo") === "perksAndDiscounts"}
                  onChange={() => selectRadioButton("perksAndDiscounts")}
                />
                <label htmlFor="sendToPerksAndDiscounts">{t("pushNotifications.sendTo.perksAndDiscounts")}</label>
              </div>
            </>
          )}
        />

        <Hideable hidden={targetType !== "clientDivision"} className="mt-2">
          <Controller
            name={"clientDivision"}
            control={control}
            rules={{ validate: ({ clientId }) => !!clientId }}
            render={({ field: { onChange } }) => (
              <>
                <ClientDivisionSelector onSelection={onChange} />
                {errors.clientDivision && <ErrorAlert content={t("pushNotifications.clientRequiredError")} />}
              </>
            )}
          />
        </Hideable>
        <Hideable hidden={targetType !== "targetGroup"} className="mt-2">
          <Controller
            name={"targetGroup"}
            control={control}
            rules={{ validate: (targetGroup) => !!targetGroup }}
            render={({ field: { onChange } }) => (
              <>
                <TargetGroupTypeAhead onTargetGroupSelected={onChange} />
                {errors.targetGroup && <ErrorAlert content={t("pushNotifications.targetGroupRequiredError")} />}
              </>
            )}
          />
        </Hideable>

        <Hideable hidden={targetType !== "localeId"} className="mt-2">
          <Controller
            name={"localeId"}
            control={control}
            rules={{ validate: (localeId) => !!localeId }}
            render={({ field }) => (
              <>
                <LocaleSelect {...{ ...field, ref: undefined }} ariaLabel={t("pushNotifications.localeSelectLabel")} />
                {errors.clientDivision && <ErrorAlert content={t("pushNotifications.localeSelectError")} />}
              </>
            )}
          />
        </Hideable>

        <Hideable hidden={targetType !== "perksAndDiscounts"} className="mt-2">
          <Controller
            name={"localeId"}
            control={control}
            render={({ field }) => (
              <>
                <LocaleSelect {...{ ...field, ref: undefined }} allOption={true}  ariaLabel={t("pushNotifications.localeSelectLabel")} />
                {errors.clientDivision && <ErrorAlert content={t("pushNotifications.localeSelectError")} />}
              </>
            )}
          />
        </Hideable>
        <div className="pt-5">
          <Controller
            name={"messageTitle"}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <Input
                  type="text"
                  id="title"
                  aria-label={t("pushNotifications.messageTitle")}
                  onChange={onChange}
                  value={value}
                  autoComplete="disabled"
                  placeholder={t("pushNotifications.messageTitle")}
                />
                {errors.messageTitle && <ErrorAlert content={t("pushNotifications.messageTitleErrorMessage")} />}
              </>
            )}
          />
        </div>

        <div className="pt-5">
          <Controller
            name={"content"}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <Textarea
                  id="title"
                  aria-label={t("pushNotifications.messageContent")}
                  onChange={onChange}
                  rows={8}
                  placeholder={t("pushNotifications.messageContent")}
                  value={value}
                />
                {errors.content && <ErrorAlert content={t("pushNotifications.messageContentErrorMessage")} />}
              </>
            )}
          />
        </div>

        <div className="flex pt-10 mt-2">
          <FormSubmitButton
            text={t("pushNotifications.sendButton")}
            onClick={() => {
              onSubmit(getValues());
            }}
            disabled={sendingNotification}
          />
        </div>
      </form>
      <ErrorAlert content={errorMessage} />
    </div>
  );
};
