import "shared/extensions/date";

import { ChangeEvent } from "react";
import { ChartFilters } from "../../../../UIPalette/components/Charts/ChartProps";
import { ClientDivisionSelector } from "../../../../UIPalette/components/ClientDivisionSelector/ClientDivisionSelector";
import DatePicker from "@brighthr/component-datepicker";
import { DivisionSelect } from "./DivisionSelect";
import { GroupingValues } from "../../../../UIPalette/components/Reports/ReportConstants";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { LocaleSelect } from "shared/components/LocaleSelect";
import { RoleKeys } from "core/Constants";
import Select from "@brighthr/component-select";
import { TargetGroupTypeAhead } from "../../../../UIPalette/components/TargetGroupTypeAhead/TargetGroupTypeAhead";
import { useAdminUserContext } from "core/state/AdminUserContext";
import { useTranslation } from "react-i18next";

export type FilterProps = {
  filters: ChartFilters;
  dispatch: Function;
  hideAllFilters: boolean;
  hideDateFilters: boolean;
  hideLocaleFilter: boolean;
};

export const ReportFilters = ({ filters, dispatch, hideAllFilters, hideDateFilters, hideLocaleFilter }: FilterProps) => {
  const { t } = useTranslation();
  const { isAuthorised, user } = useAdminUserContext();
  const isHaAdmin = isAuthorised({ role: RoleKeys.HaAdmin });

  return (
    <Hideable hidden={hideAllFilters}>
      <div className="w-full mb-4 lg:w-3/4 xl:w-1/2">
        <Hideable hidden={hideDateFilters}>
          <div className="flex flex-row mb-3.5 space-x-3.5">
            <div className="relative basis-1/2">
              <label htmlFor="dateFrom" className="text-xs absolute top-[-1px] left-2 z-10 text-sky-grey">
                {t("dashboard.filters.startDate")}
              </label>
              <DatePicker
                date={Date.fromDateOnly(filters.dateFrom)}
                setDate={(date) => dispatch({ type: "SET_FROM_DATE", payload: date })}
                {...{
                  className: "[&>input]:bg-l-blue border-r-4 border-none [&>svg]:z-10 cursor-pointer leading-8",
                  name: "dateFrom",
                }}
              />
            </div>
            <div className="relative basis-1/2">
              <label htmlFor="dateTo" className="text-xs absolute top-[-1px] left-2 z-10 text-sky-grey">
                {t("dashboard.filters.endDate")}
              </label>
              <DatePicker
                date={Date.fromDateOnly(filters.dateTo)}
                setDate={(date) => dispatch({ type: "SET_TO_DATE", payload: date })}
                {...{
                  className: "[&>input]:bg-l-blue border-r-4 border-none [&>svg]:z-10 cursor-pointer leading-8",
                  name: "dateTo",
                }}
              />
            </div>
          </div>
        </Hideable>

        <Hideable hidden={isHaAdmin}>
          <div className="flex flex-row pr-3.5 mb-3.5">
            <DivisionSelect
              clientId={user?.client?.id!}
              onChange={(division: ChangeEvent<HTMLInputElement>) =>
                dispatch({
                  type: "SET_DIVISION",
                  payload: division.target.value,
                })
              }
              ariaLabel={t("dashboard.filters.ariaDivision")}
              value={filters.divisionId ?? ""}
              placeholder={t("dashboard.filters.divisionPlaceholder")}
            />
          </div>
        </Hideable>

        <Hideable hidden={!isHaAdmin}>
          <div className="flex flex-row space-x-3.5">
            <div className="relative basis-2/3">
              <ClientDivisionSelector
                onSelection={(clientAndDivision) =>
                  dispatch({
                    type: "SET_CLIENT_AND_DIVISION",
                    payload: clientAndDivision,
                  })
                }
                clientReadOnly={!isHaAdmin}
              />
            </div>
            <div className="relative basis-1/3">
              <TargetGroupTypeAhead
                onTargetGroupSelected={(targetGroup) =>
                  dispatch({
                    type: "SET_TARGET_GROUP",
                    payload: targetGroup,
                  })
                }
              />
            </div>
          </div>
        </Hideable>

        <div className="flex flex-row space-x-3.5 w-full">
          <div className="relative w-full">
            <label htmlFor="groupingSelect" className="text-xs absolute top-[-1px] left-2 z-10 text-sky-grey">
              {t("dashboard.grouping.title")}
            </label>
            <Select
              onChange={(grouping: ChangeEvent<HTMLSelectElement>) =>
                dispatch({
                  type: "SET_GROUPING",
                  payload: grouping.target.value,
                })
              }
              aria-label={t("dashboard.grouping.ariaDropdown")}
              className="bg-l-blue border-r-4 border-none text-base lg:text-lg leading-8 !pl-2"
              name="groupingSelect"
            >
              <option value={GroupingValues.None}>{t("dashboard.grouping.noGrouping")}</option>
              <option value={GroupingValues.AgeRange}>{t("dashboard.grouping.ageRange")}</option>
              <option value={GroupingValues.Gender}>{t("dashboard.grouping.gender")}</option>
            </Select>
          </div>
          <Hideable hidden={hideLocaleFilter}>
            <LocaleSelect
              label={t("dashboard.filters.ariaLocales")}
              value={filters.localeId}
              onChange={(locale: ChangeEvent<HTMLSelectElement>) =>
                dispatch({
                  type: "SET_LOCALE",
                  payload: locale.target.value,
                })
              }
              height="h-[42px] font-semibold text-neutral-700"
              ariaLabel={t("dashboard.filters.ariaLocales")}
              allOption={true}
            />
          </Hideable>
        </div>
      </div>
    </Hideable>
  );
};
