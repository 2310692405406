import "./Dashboard.css";
import "shared/extensions/date";

import { GroupSpecificKeyedColours, XAxisSpan, moodLineChartOptions } from "../../UIPalette/components/Charts/ChartConstants";
import { GroupingValues, ReportType } from "../../UIPalette/components/Reports/ReportConstants";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useReducer, useState } from "react";

import { BarChart } from "../../UIPalette/components/Charts/BarChart";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { ExportOnly } from "../../UIPalette/components/Charts/ExportOnly";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { LineChart } from "../../UIPalette/components/Charts/LineChart";
import { PieChart } from "../../UIPalette/components/Charts/PieChart";
import { Report } from "./components/Report";
import { ReportFilters } from "./components/Filters/ReportFilters";
import { RoleKeys } from "core/Constants";
import { filtersReducer } from "./components/Filters/filtersReducer";
import { formatDimensionWithAverage } from "../../UIPalette/components/Charts/dimensionLabelFormatter";
import { useAdminUserContext } from "core/state/AdminUserContext";
import { useDefaultLocaleId } from "shared/core/hooks/useDefaultLocaleList";
import { useTranslation } from "react-i18next";
import { yAxisMoodImagePlugin } from "../../UIPalette/components/Charts/yAxisMoodImagePlugin";

type FilterVisibility = {
  hideDateFilters?: boolean;
  hideAllFilters?: boolean;
  hideLocaleFilter?: boolean;
};
export const Dashboard = () => {
  const { t } = useTranslation();
  const [filterVisibility, setFilterVisibility] = useState({
    hideDateFilters: true,
    hideAllFilters: false,
    hideLocaleFilter: false,
  });

  const defaultLocale = useDefaultLocaleId();

  const [filters, dispatch] = useReducer(filtersReducer, {
    dateFrom: Date.fromOffset(new Date(), -30).toDateOnly(),
    dateTo: new Date().toDateOnly(),
    groupBy: GroupingValues.None.toString(),
  });

  const { isAuthorised } = useAdminUserContext();
  const canViewTotalUsersReport = isAuthorised({ role: RoleKeys.HaAdmin, permissions: ["view-reports"] });

  function enableFilters(visibilityFilters?: FilterVisibility) {
    setFilterVisibility({
      ...{
        hideDateFilters: false,
        hideAllFilters: false,
        hideLocaleFilter: false,
      },
      ...visibilityFilters,
    });
  }

  return (
    <>
      <h2 className="text-black mb-3.5">{t("dashboard.title")}</h2>

      <ReportFilters
        filters={filters}
        dispatch={dispatch}
        hideAllFilters={filterVisibility.hideAllFilters}
        hideDateFilters={filterVisibility.hideDateFilters}
        hideLocaleFilter={filterVisibility.hideLocaleFilter}
      />

      <Tabs selectedTabClassName="decoration-2 decoration-primary-700 !border-primary-700 outline-none">
        <TabList className="selected-tab">
          <Tab className="tab" onClick={() => enableFilters({ hideDateFilters: true })}>
            {t("dashboard.reports.numberOfUsersTab")}
          </Tab>
          <Tab className="tab" onClick={() => enableFilters()}>
            {t("dashboard.reports.registrationsTab")}
          </Tab>
          <Tab className="tab" onClick={() => enableFilters()}>
            {t("dashboard.reports.dailyActiveUsersTab")}
          </Tab>
          <Tab className="tab" onClick={() => enableFilters({ hideDateFilters: true })}>
            {t("dashboard.reports.preferencesTab")}
          </Tab>
          <Tab className="tab" onClick={() => enableFilters()}>
            {t("dashboard.reports.supportRequestsTab")}
          </Tab>
          <Tab className="tab" onClick={() => enableFilters()}>
            {t("dashboard.reports.moodTrackersTab")}
          </Tab>
          <Tab className="tab" onClick={() => enableFilters()}>
            {t("dashboard.reports.trackersTab")}
          </Tab>
          <Tab className="tab" onClick={() => enableFilters()}>
            {t("dashboard.reports.fourWeekPlansTab")}
          </Tab>
          <Tab className="tab" onClick={() => enableFilters()}>
            {t("dashboard.reports.miniHealthChecksTab")}
          </Tab>
          <Hideable hidden={!canViewTotalUsersReport}>
            <Tab className="tab" onClick={() => enableFilters({ hideDateFilters: true, hideLocaleFilter: true})}>
              {t("dashboard.reports.totalUsersByClientTab")}
            </Tab>
          </Hideable>
        </TabList>

        <TabPanel>
          <Report title={t("dashboard.reports.numberOfUsersTab")} grouping={filters.groupBy}></Report>
          <PieChart reportType={ReportType.NumberOfUsers} filters={filters} colours={GroupSpecificKeyedColours} />
        </TabPanel>
        <TabPanel>
          <Report title={t("dashboard.reports.registrationsTab")} grouping={filters.groupBy}>
            <PieChart reportType={ReportType.Registrations} filters={filters} colours={GroupSpecificKeyedColours} />
          </Report>
        </TabPanel>
        <TabPanel>
          <Report title={t("dashboard.reports.dailyActiveUsersTab")} grouping={filters.groupBy}>
            <LineChart reportType={ReportType.DailyActiveUsers} filters={filters} colours={GroupSpecificKeyedColours} xAxisSpan={XAxisSpan.days} />
          </Report>
        </TabPanel>
        <TabPanel>
          {defaultLocale ? (
            <Report title={t("dashboard.reports.preferencesTab")} grouping={filters.groupBy}>
              <BarChart reportType={ReportType.Preferences} filters={filters} />
            </Report>
          ) : (
            <ErrorAlert title={t("dashboard.errors.genericError")} content={t("dashboard.errors.genericErrorBody")} />
          )}
        </TabPanel>
        <TabPanel>
          <Report title={t("dashboard.reports.supportRequestsTab")} grouping={filters.groupBy}>
            <BarChart reportType={ReportType.SupportRequests} filters={filters} translatedLabels={true} />
          </Report>
        </TabPanel>
        <TabPanel>
          <Report title={t("dashboard.reports.moodTrackersTab")} grouping={filters.groupBy}>
            <LineChart
              reportType={ReportType.MoodTrackers}
              filters={filters}
              colours={GroupSpecificKeyedColours}
              options={moodLineChartOptions}
              dimensionLabelFormatter={formatDimensionWithAverage}
              plugins={[yAxisMoodImagePlugin]}
            />
          </Report>
        </TabPanel>
        <TabPanel>
          <Report title={t("dashboard.reports.trackersTab")} grouping={filters.groupBy}>
            <BarChart reportType={ReportType.Trackers} filters={filters} translatedLabels={true} />
          </Report>
        </TabPanel>
        <TabPanel>
          <Report title={t("dashboard.reports.fourWeekPlansTab")} grouping={filters.groupBy}>
            <BarChart reportType={ReportType.FourWeekPlans} filters={filters} />
          </Report>
        </TabPanel>
        <TabPanel>
          <Report title={t("dashboard.reports.miniHealthChecksTab")} grouping={filters.groupBy}>
            <BarChart reportType={ReportType.MiniHealthChecks} filters={filters} />
          </Report>
        </TabPanel>
        <Hideable hidden={!canViewTotalUsersReport}>
          <TabPanel>
            <Report title={t("dashboard.reports.totalUsersByClientTab")}>
              <ExportOnly reportType={ReportType.TotalUsersByClient} filters={filters} />
            </Report>
          </TabPanel>
        </Hideable>
      </Tabs>
    </>
  );
};
